import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { LocalDataTrack, LocalTrack, Room, connect, createLocalTracks } from "twilio-video";
import { ModalComponent } from "../../components/modal/modal";
import { TryAgain } from "../../components/try-again/try-again";
import { VideoLoader } from "../../components/video-loader/video-loader";
import {
  VideoCallSettings,
  useAgentsStatusQuery,
  useEndVideoCallMutation,
  useInitiateVideoCallMutation,
  useRemoveScheduledVideoCallMutation,
  useSettingsUpdatedSubscription,
} from "../../generated/graphql";
import { useApiTranslation } from "../../helpers/apiTranslationHook";
import { getLocation, getWebcams } from "../../helpers/utils";
import { parseError } from "../../utils/parseError";
import { EndCallButton, VideoPageStyle } from "./video-call-page.style";

export interface VideoCallPageProps {
  callSettings: VideoCallSettings;
  callHandler: (value: boolean) => void;
}

export interface VideoPageState {
  token?: string;
  callId?: string;
  inProgress: boolean;
  location?: Position | null;
  error?: string;
  isIOS?: boolean;
  switchingCamera: boolean;
}

export interface RouteParams {
  platformId: string;
  customerId: string;
  locationId: string;
}

export const VideoCallPage: React.FunctionComponent<VideoCallPageProps> = ({ callSettings, callHandler }) => {
  let interval = useRef<number>();
  let userVideo: HTMLDivElement;
  let agentVideo: HTMLDivElement;
  // tslint:disable-next-line:no-any
  let localTracks = useRef<LocalTrack[]>([]);
  // tslint:disable-next-line:no-any
  let room = useRef<Room>();
  let isIOS = useRef<boolean>();
  let timeoutTimer: number;
  const { translate } = useApiTranslation();
  const { customerId, platformId, locationId } = useParams<RouteParams>();
  const [inProgress, setProgressState] = useState<boolean>(true);
  const [rotate, setRotate] = useState<number>(callSettings.locations.find((el) => el.id === locationId)?.clientSideRotation || 0);
  const [inputStreamRotate, setInputStreamRotate] = useState<number>(
    callSettings.locations.find((el) => el.id === locationId)?.clientSideRotationInputStream || 0
  );

  const [tryAgainState, setTryAgainState] = useState<boolean>(false);
  const [{ error }, setErrorState] = useState<{ error: string | null }>({
    error: null,
  });
  const [{ location }, setLocationState] = useState<{
    location: Position | null;
  }>({ location: null });
  const [{ token, callId, taskSid }, setTokenState] = useState<{
    token: string | null;
    callId: string | null;
    taskSid: string | null;
  }>({ token: null, callId: null, taskSid: null });

  const [cameraState, setCameraState] = useState<{
    selectedWebcamId: string | null;
    currentPreviewCamera?: {
      index: number;
      deviceId: string;
      localTracks: LocalTrack[];
    };
    currentPreviewCameraIndex?: number;
    webcams: MediaDeviceInfo[] | null;
    switchingCamera: boolean;
  }>({
    selectedWebcamId: null,
    webcams: null,
    switchingCamera: false,
  });
  const { data: agentsStatusData, refetch } = useAgentsStatusQuery({
    variables: {
      platformId,
      customerId,
    },
  });

  const agentStatus = agentsStatusData?.agentsStatus?.agentsStatus;
  const [endCallMutation] = useEndVideoCallMutation();
  const [removeScheduledVideoCallMutation] = useRemoveScheduledVideoCallMutation();
  const [initiateVideoCallMutation] = useInitiateVideoCallMutation();
  const [videoCallSettings, setCallSettings] = useState<VideoCallSettings | null>(null);
  const { data: updatedSettings, error: subscriptionError, loading: subscriptionLoading } = useSettingsUpdatedSubscription({
    variables: { customerId, platformId },
  });

  useEffect(() => {
    if (updatedSettings && !subscriptionError && !subscriptionLoading) {
      const newRotate = updatedSettings.settingsUpdated.locations.find((el) => el.id === locationId)?.clientSideRotation;
      const newInputStreamRotate = updatedSettings.settingsUpdated.locations.find((el) => el.id === locationId)?.clientSideRotationInputStream;
      if (newInputStreamRotate != null) {
        setInputStreamRotate(newInputStreamRotate);
      }
      if (newRotate != null) {
        setRotate(newRotate);
      }
      setCallSettings({ ...callSettings, ...{ locations: updatedSettings.settingsUpdated.locations } });
    }
  }, [updatedSettings, subscriptionLoading, subscriptionError]);
  const agentsStartPolling = (time: number): void => {
    interval.current = setInterval(() => {
      if (refetch) {
        refetch().then();
      }
    }, time);
  };

  const agentsStopPolling = (): void => {
    clearInterval(interval.current);
  };

  const initiateCall = async () => {
    try {
      clearTimeout(timeoutTimer);
      localTracks.current = await createLocalTracks({
        audio: true,
        video: {
          width: 320,
          deviceId: cameraState.selectedWebcamId || undefined,
        },
      });
      if (!localTracks.current) {
        throw Error("error.no.webcam");
      }

      localTracks.current.forEach((tr: any) => {
        const track = tr.attach();

        if (!userVideo) {
          throw new Error("error.no.webcam");
        }
        userVideo.appendChild(track);
      });
      if (platformId && customerId) {
        const { data } = await initiateVideoCallMutation({
          variables: {
            platformId,
            customerId,
            lat: location && location.coords.latitude,
            lng: location && location.coords.longitude,
            locationId,
          },
        });
        if (data?.initiateVideoCall) {
          setTokenState({
            token: data?.initiateVideoCall.token,
            callId: data?.initiateVideoCall.callId,
            taskSid: data?.initiateVideoCall.taskSid,
          });
          room.current = await connect(data?.initiateVideoCall.token, {
            tracks: localTracks.current,
          });
        }
      }
      const timeoutDelay = callSettings && callSettings.timeoutDelay;
      const redirectDelay = callSettings && callSettings.redirectDelay;

      timeoutTimer = window.setTimeout(onTimeout, ((timeoutDelay || 0) + (redirectDelay || 0)) * 1000);
      if (room.current) {
        room.current.on("participantConnected", handleParticipantConnected);
        room.current.on("participantDisconnected", handleParticipantDisconnect);
      }
    } catch (error) {
      setErrorState({ error: parseError(error) });
      await endCall(true);
    }
  };
  const destroyVideo = (timeout: number = 0) => {
    agentsStopPolling();
    if (localTracks.current && room.current) {
      localTracks.current.forEach((track: any) => {
        if (!(track instanceof LocalDataTrack)) {
          track.stop();
          track.mediaStreamTrack.stop();
        }
      });
      localTracks.current.splice(0, localTracks.current.length);
    }
    if (userVideo) {
      while (userVideo.firstChild) {
        userVideo.removeChild(userVideo.firstChild);
      }
    }

    if (agentVideo) {
      while (agentVideo.firstChild) {
        agentVideo.removeChild(agentVideo.firstChild);
      }
    }
    if (room.current) {
      room.current.disconnect();
    }

    setTimeout(() => {
      window.location.reload();
    }, timeout);
  };

  useEffect(() => {
    if (agentStatus) {
      if (!interval.current) {
        agentsStartPolling(5000);
      }

      isIOS.current = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window["MSStream"];
      if (agentStatus === "AVAILABLE") {
        agentsStopPolling();
        setErrorState({ error: null });
        initiateCall().then();
      }
      if (agentStatus !== "CLOSED") {
        getWebcams().then(({ error, data }) => {
          if (error) {
            setErrorState({ error });
          } else if (data) {
            setCameraState({
              ...cameraState,
              currentPreviewCamera: {
                index: 0,
                deviceId: data.webcams[0].deviceId,
                localTracks: localTracks.current,
              },
              webcams: data.webcams,
              currentPreviewCameraIndex: 0,
            });
          }
        });
      } else {
        setErrorState({ error: "error.closed" });
      }
    }

    return () => {
      // destroyVideo();
    };
  }, [agentStatus]);

  const selectWebcam = async (webcamId: string, index: number) => {
    const { currentPreviewCamera } = cameraState;

    if (currentPreviewCamera?.deviceId !== webcamId) {
      if (currentPreviewCamera && currentPreviewCamera.localTracks) {
        for (const track of currentPreviewCamera.localTracks) {
          if (!(track instanceof LocalDataTrack)) {
            track.stop();
          }
        }
      }
      await updateLocalTracks(webcamId, index);
    } else {
      setCameraState({
        ...cameraState,
        selectedWebcamId: webcamId,
      });
      setLocationState({
        location: await getLocation(),
      });
    }
  };

  const updateLocalTracks = async (deviceId: string, index: number) => {
    let facingMode: { exact: string } = { exact: "user" };

    if (index === 0) {
      facingMode.exact = "user";
    } else {
      facingMode.exact = "environment";
    }

    localTracks.current = await createLocalTracks({
      audio: false,
      video: {
        width: 320,
        facingMode,
        deviceId,
      },
    });
    while (userVideo.firstChild) {
      userVideo.removeChild(userVideo.firstChild);
    }
    await localTracks.current.forEach((tr: any) => {
      const track = tr.attach();

      if (!userVideo) {
        throw new Error("error.no.webcam");
      }
      userVideo.appendChild(track);
    });

    setCameraState({
      ...cameraState,
      selectedWebcamId: cameraState.selectedWebcamId,
      currentPreviewCamera: {
        index,
        deviceId,
        localTracks: localTracks.current,
      },
      currentPreviewCameraIndex: index,
      switchingCamera: false,
    });
  };

  const switchPreview = async (deviceId: string, index: number) => {
    if (cameraState.currentPreviewCamera && cameraState.currentPreviewCamera.localTracks) {
      for (const track of cameraState.currentPreviewCamera.localTracks) {
        if (!(track instanceof LocalDataTrack)) {
          track.stop();
        }
      }
    }

    setCameraState({
      ...cameraState,
      switchingCamera: true,
      currentPreviewCameraIndex: index,
    });

    try {
      await updateLocalTracks(deviceId, index);
    } catch (error) {
      console.error(error);
    }
  };

  const endCall = async (withoutCallBack?: boolean) => {
    agentsStopPolling();
    clearTimeout(timeoutTimer);

    if (callId && taskSid) {
      await endCallMutation({
        variables: {
          platformId,
          customerId,
          callId,
          taskSid,
        },
      });
    }

    setProgressState(false);
    setTokenState({
      token: null,
      callId: null,
      taskSid: null,
    });

    destroyVideo();

    if (!withoutCallBack) {
      callHandler(false);
    }
  };

  const setUserVideo = (el: HTMLDivElement | null) => {
    if (el) {
      userVideo = el;
    }
  };

  const setAgentVideo = (el: HTMLDivElement | null) => {
    if (el) {
      agentVideo = el;
    }
  };

  const tryAgain = async () => {
    setTryAgainState(false);

    setProgressState(true);

    agentsStartPolling(5000);
    clearTimeout(timeoutTimer);

    if (agentStatus === "AVAILABLE") {
      await initiateCall();
    }
  };

  const onTimeout = async () => {
    setTryAgainState(true);
    await endCall(true);
  };

  const handleParticipantConnected = (participant: any) => {
    clearTimeout(timeoutTimer);
    setProgressState(false);
    participant.tracks.forEach((publication: any) => {
      if (agentVideo) {
        if (publication.isSubscribed) {
          agentVideo.appendChild(publication.track.attach());
        }
      }

      publication.on("subscribed", (track: any) => {
        if (agentVideo) {
          agentVideo.appendChild(track.attach());
        }
      });
    });
    participant.on("trackAdded", async (track: any) => {
      if (callId != null) {
        await removeScheduledVideoCallMutation({ variables: { platformId, customerId, callId } });
      }
      if (agentVideo) {
        agentVideo.appendChild(track.attach());
      }
    });
  };

  const handleParticipantDisconnect = () => {
    setProgressState(false);
    clearTimeout(timeoutTimer);
    agentsStopPolling();
    destroyVideo();
    callHandler(false);
  };

  return (
    <VideoPageStyle>
      {(token || inProgress) && (
        <EndCallButton onClick={() => endCall()}>
          <img className={"end-call-icon"} width={"30px"} src={require("../../assets/icons/phone-hang-up.svg")} alt="null" />
        </EndCallButton>
      )}
      {tryAgainState && <TryAgain tryAgain={tryAgain} callSettings={callSettings} callHandler={endCall}></TryAgain>}
      {inProgress && (
        <VideoLoader
          callSettings={callSettings}
          agentStatus={agentStatus}
          destroyVideo={destroyVideo}
          location={location}
          token={token}
          selectedWebcamId={cameraState.selectedWebcamId}
        />
      )}
      {/*<CameraSelect cameraState={cameraState} agentStatus={agentStatus} selectWebcam={selectWebcam} switchPreview={switchPreview}/>*/}
      {error && (
        <ModalComponent show={true} videoCallSettings={callSettings} callHandler={endCall}>
          {translate(error, callSettings)}
        </ModalComponent>
      )}
      <img className="logo" alt="Logo" src={callSettings?.logoImage} />
      {/*{locationId && (*/}
      {/*  <RotateVideoButton*/}
      {/*    initialRotate={rotate}*/}
      {/*    colors={{ backgroundColor: callSettings.colors.buttonsBackground, textColor: callSettings.colors.buttonsText }}*/}
      {/*    rotate={(deg: number) => {*/}
      {/*      setRotate(deg);*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}

      <div
        className="userVideo"
        style={{
          transformOrigin: "center",
          transform: `rotate(${inputStreamRotate}deg)`,
        }}
        ref={setUserVideo}
      />
      <div
        className="agentVideo"
        style={{
          transformOrigin: "center",
          transform: `rotate(${rotate}deg)`,
        }}
        ref={setAgentVideo}
      />
    </VideoPageStyle>
  );
};
